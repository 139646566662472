import React, { useEffect, useState } from 'react'
import { SubPageTab } from '../model/subPageTabArea'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

// interface
interface OnwProps {
   data: SubPageTab[];
   setTabIndex?: (index: number) => void;
   currentId?: number;
}

// style
const Container = styled.ul`
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   width: fit-content;
   max-width: 1280px;
   margin-left: auto;
   margin-right: auto;
   & .item{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 320px;
      height: 60px;
      border: 1px solid var(--subColor05);
   }
   & .item.on{
      border-color: var(--pointColor01);
      color: #fff;;
      background-color: var(--pointColor01);
   }
   @media screen and (max-width:1280px){
      width: 100%;
      padding: 0px 16px;
      .list{
         width: 33.3%;
         height: 40px;
         .item{
            width: 100%;
            height: 100%;
         }
      }
   }
   @media screen and (max-width: 767px){
      .list{
         width: 50%;
      }
   }
`

const SubPageTabArea: React.FC<OnwProps> = ({ data, setTabIndex, currentId }) => {

   // const [activeIndex, setActiveIndex] = useState(currentId ? currentId : 0);

   const navigate = useNavigate();
   const handleUpdateQuery = (index:number) => {
      navigate(`?${index}`)
   }

   return (
      <Container className='tab_wrap xl:mt-80 mt-40 xl:mb-80 mb-40'>
         {
            data.map((info, index) => (
               <li className='list' key={index}>
                  <button
                     // onClick={() => {
                     //    setActiveIndex(index);
                     //    setTabIndex && setTabIndex(index);
                     // }}
                     onClick={() => handleUpdateQuery(index)}
                     className={`item ${currentId === index ? "on" : ""} xl:text-16 text-14 font-semibold`}
                  >
                     {info.name}
                  </button>
               </li>
            ))
         }
      </Container >
   )
}

export default SubPageTabArea