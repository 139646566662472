import { useState, useMemo, useCallback, useEffect } from "react";

// type
import { SubTopArea } from "../../../model/subPageTopArea"
import { SubPageTab } from "../../../model/subPageTabArea"

// components
import SubPageTopArea from "../../../components/SubPageTopArea"
import SubPageTabArea from "../../../components/SubPageTabArea"

// pages
import EcoLabel from "./EcoLabel"
import EpcCert from "./EpcCert"
import GrCert from "./GrCert"
import GroupCert from "./GroupCert"
import GsCert from "./GsCert"
import HighEffEnergy from "./HighEffEnergy"
import Iso9001 from "./Iso9001"
import Iso14001 from "./Iso14001"
import KcCert from "./KcCert"
import KMark from "./KMark"
import QMark from "./QMark"
import SMark from "./SMark"

// images
import bannerBg02 from "../../../images/certification/banner_bg02.png"
import { useLocation } from "react-router-dom";

const QualityCertCommon = () => {

   const location = useLocation();
   const queryString = Number(location.search.substring(1));

   // TopArea, Tab
   const subPageInfo: SubTopArea = {
      title: "인증 분야",
      category: [
         { name: "조달인증", link: "/certification/procurement" },
         { name: "품질인증", link: "/certification/quality-cert" },
         { name: "기술인증", link: "/certification/tech-cert" },
         { name: "경영인증", link: "/certification/management-cert" },
      ],
      bannerText: "우수조달컨설팅은 <span class='point'>확실한 신용과 빅데이터</span>를 활용하여 <br/><span class='point'>정확한 컨설팅</span>을 진행합니다 ",
      currentInfo: {
         name: "품질인증",
         bgImg: bannerBg02,
      },
   };

   const subPageTab: SubPageTab[] = [
      { name: "성능인증(EPC)" },
      { name: "K마크" },
      { name: "환경표지인증" },
      { name: "KC인증" },
      { name: "GR인증" },
      { name: "GS인증" },
      { name: "단체표준인증" },
      { name: "고효율에너지" },
      { name: "S마크인증" },
      { name: "Q마크인증" },
      { name: "ISO9001" },
      { name: "ISO14001" },
   ];

   const [tabIndex, setTabIndex] = useState(queryString ? queryString : 0);

   useEffect(() => {
      setTabIndex(queryString ? queryString : 0)
   }, [queryString])

   const components = useMemo(() => [
      <EpcCert />,
      <KMark />,
      <EcoLabel />,
      <KcCert />,
      <GrCert />,
      <GsCert />,
      <GroupCert />,
      <HighEffEnergy />,
      <SMark />,
      <QMark />,
      <Iso9001 />,
      <Iso14001 />,
   ], []);

   const returnComponent = useCallback(() => {
      return components[tabIndex];
   }, [tabIndex, components]);

   return (
      <div>
         <SubPageTopArea info={subPageInfo} />
         <SubPageTabArea data={subPageTab} setTabIndex={setTabIndex} currentId={queryString} />
         {returnComponent()}
      </div>
   );
}

export default QualityCertCommon;
