import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from 'react-router-dom';

// type
import { SubTopArea } from "../../../model/subPageTopArea"
import { SubPageTab } from "../../../model/subPageTabArea"

// components
import SubPageTopArea from "../../../components/SubPageTopArea"
import SubPageTabArea from "../../../components/SubPageTabArea"

// pages
import ExcellentProcurement from "./ExcellentProcurement";
import Innoproduct from "./Innoproduct"
import MultiSupplier from "./MultiSupplier"
import QualityGuarantee from "./QualityGuarantee"
import VentureRegistration from "./VentureRegistration"
import ExcellenceCert from "./ExcellenceCert"
import DigitalService from "./DigitalService"
import Software3party from "./Software3party"
import GPass from "./GPass"

// images
import bannerBg01 from "../../../images/certification/banner_bg01.png"

const ProcurementCommon = () => {

   const location = useLocation();
   const queryString = Number(location.search.substring(1));

   // TopArea, Tab
   const subPageInfo: SubTopArea = {
      title: "인증 분야",
      category: [
         { name: "조달인증", link: "/certification/procurement" },
         { name: "품질인증", link: "/certification/quality-cert" },
         { name: "기술인증", link: "/certification/tech-cert" },
         { name: "경영인증", link: "/certification/management-cert" },
      ],
      bannerText: "우수조달컨설팅은 <span class='point'>확실한 신용과 빅데이터</span>를 활용하여 <br/><span class='point'>정확한 컨설팅</span>을 진행합니다 ",
      currentInfo: {
         name: "조달인증",
         bgImg: bannerBg01
      },
   }
   const subPageTab: SubPageTab[] = [
      { name: "우수조달제품인증" },
      { name: "혁신제품인증" },
      { name: "다수공급자계약(MAS)" },
      { name: "품질보증조달물품인증" },
      { name: "벤처나라 등록" },
      { name: "우수조달공동상표인증" },
      { name: "디지털서비스인증" },
      { name: "소프트웨어 3자단가계약" },
      { name: "G-PASS" },
   ]

   const [tabIndex, setTabIndex] = useState(queryString ? queryString : 0);

   useEffect(() => {
      setTabIndex(queryString ? queryString : 0)
   }, [queryString])

   const components = useMemo(() => [
      <ExcellentProcurement />,
      <Innoproduct />,
      <MultiSupplier />,
      <QualityGuarantee />,
      <VentureRegistration />,
      <ExcellenceCert />,
      <DigitalService />,
      <Software3party />,
      <GPass />,
   ], []);

   const returnComponent = useCallback(() => {
      return components[tabIndex];
   }, [tabIndex, components]);

   return (
      <div>
         <SubPageTopArea info={subPageInfo} />
         <SubPageTabArea data={subPageTab} setTabIndex={setTabIndex} currentId={queryString} />
         {returnComponent()}
      </div>
   );
}

export default ProcurementCommon;