import { useEffect, useState } from 'react';

type UseIsMobileProps = {
   responsive: number; // 숫자 타입을 명시
};

const useIsMobile = ({responsive}:UseIsMobileProps) => {
   const [isMobile, setIsMobile] = useState(window.innerWidth <= responsive);

   useEffect(() => {
      const mediaQuery = window.matchMedia(`(max-width: ${responsive}px)`);

      const handleChange = (event:any) => {
         setIsMobile(event.matches);
      };

      mediaQuery.addEventListener('change', handleChange);

      return () => mediaQuery.removeEventListener('change', handleChange);
   }, []);

   return isMobile;
};

export default useIsMobile;
