import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

// images
import logo from "../images/common/logo.png"
import logoWhite from "../images/common/logo_white.png"
import { useEffect, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";

// styled
const Container = styled.header`
   z-index: 9980;
   position: fixed;
   top: 0px;
   left: 0px;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   width: 100%;
   height: 80px;
   border-bottom: 1px solid rgba(255,255,255,0.3);
   & *{
      color: #fff;
   }
   &.on{
      border-bottom: 1px solid var(--subColor05);
      background-color: #fff;
   }
   &.on *{
      color: var(--subColor01);
   }
   &.on .btn_menu_wrap{
      & .btn_menu{
         color: var(--subColor01);
      }
   }
   & .logo_normal{
      display: none;
   }
   & .logo_white{
      display: block;
   }
   &.on .logo_normal{
      display: block;
   }
   &.on .logo_white{
      display: none;
   }
   & .tel_wrap{
      line-height: 1em;
      border: 1px solid #fff;
      border-radius: 9999px;
      padding: 12px 24px;
      color: #fff;
      & *{
         color: #fff;
      }
   }
   &.on .tel_wrap{
      border-color: var(--pointColor01);
      background-color: var(--pointColor01);
   }
   & .right_item_wrap{
      height: 80px;
   }
   .category_dep02{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 50px;
      .icon-plus{
         display: flex;
         justify-content: center;
         align-items: center;
         width: 14px;
         height: 14px;
         font-size: 14px;
         border: 1px solid #e4e4e4;
         margin-left: 8px;
         color: var(--pointColor01);
      }
   }
   .dep3-items-container{
      display: none;
      &.on{
         display: block;
      }
      .dep3-item{
         text-align: center;
         font-size: 13px;
         padding: 4px 0px;
         color: #707070;
         &:hover{
            text-decoration: underline ;
            color: #0525c9;
         }
      }
   }
   .btn-site-map{
      .btn_menu{
         font-size: 26px;
      }
   }
   .scrolling-wrap{
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
   }
   .site-map-container{
      width: 100%;
      height: auto;
      background-color: #f5f5f5;
      .site-map{
         width: 1440px;
         padding: 80px 0px;
         margin-left: auto;
         margin-right: auto;
         .category:not(:last-of-type){
            border-bottom: 1px solid var(--subColor04);
            .dep3-item{
               min-width: 205px;
               color: var(--subColor03);
               &:hover{
                  text-decoration: underline;
               }
            }
         }
      }
   }
   /* pc */
   @media screen and (min-width:1280px){
      &.hover{
         height: auto;
         padding-bottom: 20px;
         background-color: #fff;
         & .logo_normal{
            display: block;
         }
         & .logo_white{
            display: none;
         }
         & .item_dep02_wrap{
            display: block;
         }
         & .tel_wrap{
            border-color: var(--pointColor01);
            background-color: var(--pointColor01);
         }
      }
      &.hover *{
         color: var(--subColor01);
      }
      &.hover .tel_wrap{
         color: #fff;
      }
      &.hover .tel_wrap *{
         color: #fff;
      }
   }

   /* 저해상도 */
   @media screen and (max-width:1500px){
      *{
         font-size: 15px;
      }
      .category_dep01,
      .category_dep02{
         width: 150px;
      }
      .tel_wrap {
         white-space: nowrap;
      }
      .scrolling-wrap{
         display: none;
      }
      .btn-site-map{
         display: none;
      }
   }

   /* 모바일 */
   @media screen and (max-width:1280px){
      height: 50px;
      .btn_menu_wrap {
         .btn_menu{
            font-size: 26px;
         }
      }
      &.open{
         height: auto;
      }
      &.open .category_wrap{
         overflow: hidden scroll;
         display: block;
         position: absolute;
         top: 50px;
         left: 0px;
         width: 100%;
         height: calc(100vh - 50px);
         padding-top: 24px;
         padding-bottom: 80px;
         background-color: #fff;
         .category_dep01, .category_dep02{
            justify-content: flex-start;
            width: 100%;
            height: 45px;
            text-align: left;
            padding: 0px 16px;
         }
         .category_dep01{
            font-weight: 600;
            &.on+.item_dep02_wrap{
               display: block;
            }
            &.on>.icon_arrow{
               transform: rotate(180deg);
            }
         }
         .category_dep02{
            height: 40px;
            padding: 0px 24px;
            font-size: 13px;
            background-color: #f5f5f5;
         }
      }
      .right_item_wrap{
         height: 50px;
         .btn_menu_wrap {
            width: 50px;
            height: 50px;
         }
      }
      .dep3-items-container .dep3-item{
         height: 35px;
         line-height: 35px;
         text-align: left;
         padding: 0px 24px;
      }
      .category_dep02 {
         display: flex;
         justify-content: space-between !important;
         width: 100%;
         .icon-plus{
            display: none;
         }
      }
   }
`;
const CategoryWrap = styled.ul` 
   display: flex;
   & .item_dep02_wrap{
      display: none;
   }
   @media screen and (max-width:1280px){
      display: none;
   }
`;
const Logo = styled(Link)`
   display: flex;
   justify-content: center;
   align-items: center;
   width: auto;
   height: 80px;
   @media screen and (max-width:1280px){
      height: 50px;
   }
`
const CategoryItemDep01 = styled.p`
   display: flex;
   justify-content: center;
   align-items: center;
   width: 200px;
   height: 80px;
`;
const MenuIcon = styled.button`
   display: block;
   width: 80px;
   height: 80px;
   .btn_menu{
      font-size: 26px;
   }
   @media screen and (min-width:1280px){
      display: none;
      .btn_menu{
         font-size: 30px;
      }
   }
   /* @media screen and (max-width:1280px) {
      display: none;
   } */
`

export default function Header() {

   const location = useLocation()
   const pathname = useLocation().pathname
   const [headerActive, setHeaderActive] = useState<number>(0);
   const [categoryIndex, setCategoryIndex] = useState<number | string>('');
   const isMobile = document.documentElement.clientWidth < 1280 ? true : false;
   const [isHover, setIsHover] = useState(false);

   const headerIndex = () => {
      setHeaderActive((prev => prev === 0 ? 1 : 0))
   }

   useEffect(() => {

      const header = document.getElementById("header")

      const btnMenu = document.querySelector(".btn_menu_wrap")
      if (btnMenu) {
         btnMenu.addEventListener("click", () => {
            header?.classList.add("on", "mouseover")
         })
      }

   }, [isMobile])


   // gnb click
   const categoryItemClick = (index: number) => {
      setCategoryIndex((prev) => prev === index ? '' : index)
   }

   // depth3 클릭시 노출여부
   const [isVisibleDep3, setIsVisibleDep3] = useState<boolean[]>([
      false,
      false,
      false,
      false,
   ]);
   const categoryDep3Click = (targetIndex: number) => {
      setIsVisibleDep3((prev) => {

         if (prev[targetIndex]) {
            return prev.map(() => false)
         }

         // targetIndex만 true로 설정
         return prev.map((_, index) => index === targetIndex);
      });
   };

   // 사이트맵 노출여부
   const [isVisibleSiteMap, setIsVisibleSiteMap] = useState(false);
   const handleSiteMapOpen = () => {
      setIsVisibleSiteMap(true)
   }
   const handleSiteMapClose = () => {
      setIsVisibleSiteMap(false)
   }

   // 사이트맵 활성화 or 헤더 active 상태일 때 스크롤 방지
   useEffect(() => {
      if (isVisibleSiteMap || headerActive === 1) {
         document.body.classList.add('disable-scroll')
      } else {
         document.body.classList.remove('disable-scroll')
      }
   }, [isVisibleSiteMap, headerActive])

   // 1500px 이하일 때 isVisibleSiteMap 비활성화
   const is1500Under = useIsMobile({ responsive: 1500 });
   useEffect(() => {
      if (is1500Under) {
         setIsVisibleSiteMap(false);
      }
   }, [is1500Under])

   // 1280px 이하일 때만 headerActive 활성화
   const is1280Under = useIsMobile({ responsive: 1280 });
   useEffect(() => {
      if (!is1280Under) {
         setHeaderActive(0);
      }
   }, [is1280Under])


   // pathname 변경 시 스크롤 맨위로
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname])

   useEffect(() => {
      const header = document.getElementById("header");

      header?.classList.remove("open");
      setIsHover(false);
      setIsVisibleSiteMap(false);
      setHeaderActive(0);

   }, [location]);

   console.log('ha', headerActive)

   return (
      <Container
         id="header"
         className={`${headerActive === 1 ? "open" : ""} ${isHover === true ? "hover" : ""} on`}
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => setIsHover(false)}
      >
         <Logo className="xl:ml-40 ml-16" to="/">
            <img className="xl:h-48 h-34 logo_normal" src={logo} alt="logo" />
            <img className="xl:h-48 h-34 logo_white" src={logoWhite} alt="logo" />
         </Logo>
         <CategoryWrap className="category_wrap">
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 0 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => { categoryItemClick(0) }}
               >
                  회사소개
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className="item_dep02_wrap">
                  <li>
                     <Link className="category_dep02" to="/company/about">우수조달컨설팅 소개</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/company/certified-company">인증성공업체</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/company/youtube">유튜브 소개</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/company/consultant">컨설턴트 소개</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/company/contact">찾아오시는 길</Link>
                  </li>
               </ul>
            </li>
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 1 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => { categoryItemClick(1) }}
               >
                  인증 분야
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className="item_dep02_wrap">
                  <li>
                     <button
                        onClick={() => categoryDep3Click(0)}
                        className="category_dep02"
                     >
                        조달인증
                        <i className={`icon-plus xi-${isVisibleDep3[0] ? 'minus' : 'plus'}-min`}></i>
                        <i className={`xl:hidden xi-angle-${isVisibleDep3[0] ? 'up' : 'down'}-min`}></i>
                     </button>
                     <ul className={`${isVisibleDep3[0] ? 'on' : ''} dep3-items-container`}>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement'>우수조달제품인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?1'>혁신제품인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?2'>다수공급자계약(MAS)</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?3'>품질보증조달물품인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?4'>벤처나라 등록</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?5'>우수조달공동상표인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?6'>디지털서비스인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?7'>소프트웨어 3자단가계약</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/procurement?8'>G-PASS</Link>
                        </li>
                     </ul>
                  </li>
                  <li>
                     <button
                        onClick={() => categoryDep3Click(1)}
                        className="category_dep02"
                     >
                        품질인증
                        <i className={`icon-plus xi-${isVisibleDep3[1] ? 'minus' : 'plus'}-min`}></i>
                        <i className={`xl:hidden xi-angle-${isVisibleDep3[1] ? 'up' : 'down'}-min`}></i>
                     </button>
                     <ul className={`${isVisibleDep3[1] ? 'on' : ''} dep3-items-container`}>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert'>성능인증(EPC)</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?1'>K마크</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?2'>환경표지인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?3'>KC인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?4'>GR인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?5'>GS인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?6'>단체표준인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?7'>고효율에너지</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?8'>S마크인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?9'>Q마크인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?10'>ISO9001</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/quality-cert?11'>ISO14001</Link>
                        </li>
                     </ul>
                  </li>
                  <li>
                     <button
                        onClick={() => categoryDep3Click(2)}
                        className="category_dep02"
                     >
                        기술인증
                        <i className={`icon-plus xi-${isVisibleDep3[2] ? 'minus' : 'plus'}-min`}></i>
                        <i className={`xl:hidden xi-angle-${isVisibleDep3[2] ? 'up' : 'down'}-min`}></i>
                     </button>
                     <ul className={`${isVisibleDep3[2] ? 'on' : ''} dep3-items-container`}>
                        <li>
                           <Link className="dep3-item" to='/certification/tech-cert'>신제품인증(NEP)</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/tech-cert?1'>신기술인증(NET)</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/tech-cert?2'>KS인증</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/tech-cert?3'>녹색기술인증</Link>
                        </li>
                     </ul>
                  </li>
                  <li>
                     <button
                        onClick={() => categoryDep3Click(3)}
                        className="category_dep02"
                     >
                        경영인증
                        <i className={`icon-plus xi-${isVisibleDep3[3] ? 'minus' : 'plus'}-min`}></i>
                        <i className={`xl:hidden xi-angle-${isVisibleDep3[3] ? 'up' : 'down'}-min`}></i>
                     </button>
                     <ul className={`${isVisibleDep3[3] ? 'on' : ''} dep3-items-container`}>
                        <li>
                           <Link className="dep3-item" to='/certification/management-cert'>벤처기업</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/management-cert?1'>이노비즈</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/management-cert?2'>기업부설연구소</Link>
                        </li>
                        <li>
                           <Link className="dep3-item" to='/certification/management-cert?3'>메인비즈</Link>
                        </li>
                     </ul>
                  </li>
               </ul>
            </li>
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 2 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => { categoryItemClick(2) }}
               >
                  원스톱 서비스
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className={`${categoryIndex === 2 ? "on" : ""} item_dep02_wrap`}>
                  <li>
                     <Link className="category_dep02" to="/one-stop/service-info">서비스 내용</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/one-stop/details">세부 내용</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/one-stop/service-staff">서비스 인력</Link>
                  </li>
               </ul>
            </li>
            <li>
               <CategoryItemDep01
                  className={`${categoryIndex === 3 ? "on" : ""} category_dep01 xl:text-20`}
                  onClick={() => { categoryItemClick(3) }}
               >
                  고객지원
                  <i className="icon_arrow xl:hidden inline ml-auto text-15 xi-angle-down-min"></i>
               </CategoryItemDep01>
               <ul className="item_dep02_wrap">
                  <li>
                     <Link className="category_dep02" to="/customer/notice">공지사항</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/customer/information">정보공유</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/customer/careers">채용정보</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/customer/faq">자주묻는 질문</Link>
                  </li>
                  <li>
                     <Link className="category_dep02" to="/customer/contact">문의하기</Link>
                  </li>
               </ul>
            </li>
         </CategoryWrap>
         <div className="right_item_wrap flex items-center xl:gap-20 xl:pr-40">
            <a
               className="tel_wrap xl:flex hidden items-center xl:gap-8 xl:text-18"
               href="tel:031-234-2870"
            >
               <i className="xi-call"></i>
               031-234-2870
            </a>
            <MenuIcon onClick={() => headerIndex()} className="btn_menu_wrap">
               <i className="btn_menu xi-align-right text-white text-30"></i>
            </MenuIcon>
            <button
               onClick={handleSiteMapOpen}
               className="btn-site-map xl:block hidden"
            >
               <i className="btn_menu xi-align-right text-white text-30"></i>
            </button>
         </div>
         {isVisibleSiteMap &&
            <div className="scrolling-wrap site-map-wrap">
               <div className="site-map-container">
                  <div className="flex justify-between items-center">
                     <Logo className="xl:ml-40 ml-16" to="/">
                        <img className="xl:h-48 h-34 logo_normal" src={logo} alt="logo" />
                     </Logo>
                     <button
                        onClick={handleSiteMapClose}
                        className="pr-40"
                     >
                        <i className="btn_menu xi-close text-black xl:text-30 text-26"></i>
                     </button>
                  </div>
                  <div className="site-map">
                     <div>
                        <ul>
                           <li className="category pb-60 mb-60">
                              <strong className="leading-1em text-32 font-bold">회사소개</strong>
                              <ul className="flex gap-100 mt-48">
                                 <li>
                                    <Link to='/company/about' className="text-20 font-semibold">우수조달컨설팅 소개</Link>
                                 </li>
                                 <li>
                                    <Link to='/company/certified-company?page=1&search=' className="text-20 font-semibold">인증성공업체</Link>
                                 </li>
                                 <li>
                                    <Link to='/company/youtube?page=1&search=' className="text-20 font-semibold">유튜브 소개</Link>
                                 </li>
                                 <li>
                                    <Link to='/company/consultant' className="text-20 font-semibold">컨설턴트 소개</Link>
                                 </li>
                                 <li>
                                    <Link to='/company/contact' className="text-20 font-semibold">찾아오시는 길</Link>
                                 </li>
                              </ul>
                           </li>
                           <li className="category pb-60 mb-60">
                              <strong className="leading-1em text-32 font-bold">인증분야</strong>
                              <ul className="flex flex-col gap-56 mt-48">
                                 <li className="flex gap-120">
                                    <strong className="text-20 font-semibold">조달인증</strong>
                                    <ul className="flex flex-wrap gap-y-40">
                                       <li>
                                          <Link to='/certification/procurement' className="dep3-item text-18">우수조달제품인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?1' className="dep3-item text-18">혁신제품인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?2' className="dep3-item text-18">다수공급자계약(MAS)</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?3' className="dep3-item text-18">품질보증조달물품인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?4' className="dep3-item text-18">벤처나라 등록</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?5' className="dep3-item text-18">우수조달공동상표인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?6' className="dep3-item text-18">디지털서비스인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?7' className="dep3-item text-18">소프트웨어 3자단가계약</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/procurement?8' className="dep3-item text-18">G-PASS</Link>
                                       </li>
                                    </ul>
                                 </li>
                                 <li className="flex gap-120">
                                    <strong className="text-20 font-semibold">품질인증</strong>
                                    <ul className="flex flex-wrap gap-y-40">
                                       <li>
                                          <Link to='/certification/quality-cert' className="dep3-item text-18">성능인증(EPC)</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?1' className="dep3-item text-18">K마크</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?2' className="dep3-item text-18">환경표지인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?3' className="dep3-item text-18">KC인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?4' className="dep3-item text-18">GR인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?5' className="dep3-item text-18">GS인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?6' className="dep3-item text-18">단체표준인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?7' className="dep3-item text-18">고효율에너지</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?8' className="dep3-item text-18">S마크인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?9' className="dep3-item text-18">Q마크인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?10' className="dep3-item text-18">ISO9001</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/quality-cert?11' className="dep3-item text-18">ISO14001</Link>
                                       </li>
                                    </ul>
                                 </li>
                                 <li className="flex gap-120">
                                    <strong className="text-20 font-semibold">기술인증</strong>
                                    <ul className="flex flex-wrap gap-y-40">
                                       <li>
                                          <Link to='/certification/tech-cert' className="dep3-item text-18">NEP</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/tech-cert?1' className="dep3-item text-18">NET</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/tech-cert?2' className="dep3-item text-18">KS인증</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/tech-cert?3' className="dep3-item text-18">녹색기술인증</Link>
                                       </li>
                                    </ul>
                                 </li>
                                 <li className="flex gap-120">
                                    <strong className="text-20 font-semibold">경영인증</strong>
                                    <ul className="flex flex-wrap gap-y-40">
                                       <li>
                                          <Link to='/certification/management-cert' className="dep3-item text-18">벤처기업</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/management-cert?1' className="dep3-item text-18">이노비즈</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/management-cert?2' className="dep3-item text-18">기업부설연구소</Link>
                                       </li>
                                       <li>
                                          <Link to='/certification/management-cert?3' className="dep3-item text-18">메인비즈</Link>
                                       </li>
                                    </ul>
                                 </li>
                              </ul>
                           </li>
                           <li className="category pb-60 mb-60">
                              <strong className="leading-1em text-32 font-bold">원스톱서비스</strong>
                              <ul className="flex gap-100 mt-48">
                                 <li>
                                    <Link to='/one-stop/service-info' className="text-20 font-semibold">서비스내용</Link>
                                 </li>
                                 <li>
                                    <Link to='/one-stop/details' className="text-20 font-semibold">세부내용</Link>
                                 </li>
                                 <li>
                                    <Link to='/one-stop/service-staff' className="text-20 font-semibold">서비스인력</Link>
                                 </li>
                              </ul>
                           </li>
                           <li className="category">
                              <strong className="leading-1em text-32 font-bold">고객지원</strong>
                              <ul className="flex gap-100 mt-48">
                                 <li>
                                    <Link to='/customer/notice?page=1&search=' className="text-20 font-semibold">공지사항</Link>
                                 </li>
                                 <li>
                                    <Link to='/customer/information?page=1&search=' className="text-20 font-semibold">정보공유</Link>
                                 </li>
                                 <li>
                                    <Link to='/customer/careers' className="text-20 font-semibold">채용정보</Link>
                                 </li>
                                 <li>
                                    <Link to='/customer/faq?page=1' className="text-20 font-semibold">자주묻는질문</Link>
                                 </li>
                                 <li>
                                    <Link to='/customer/contact' className="text-20 font-semibold">문의하기</Link>
                                 </li>
                              </ul>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         }
      </Container>
   )
}