import { useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components"

// type
import { HistoryData } from "../../model/historyData"
import { SubPageTab } from "../../model/subPageTabArea"

// components
import { Distinction } from "../../components/SubPageCommonUI"
import SubPageTabArea from "../../components/SubPageTabArea"

// images
import circle01 from "../../images/common/circle01.png"
import ceoName from "../../images/company/ceo_name.png"
import ceoImage from "../../images/company/ceo-image.png"
import { useLocation } from "react-router-dom"

// style
const Container = styled.div`
  & .ceo_text_wrap{
      background-color: var(--subColor10);
      & .point{
         color: var(--pointColor01);
      }
      & .ceo_name{
         width: auto;
         max-height: 48px;
      }
      & .ceo_profile_wrap{
         & .item{
            position: relative;
            color: var(--subColor03);
         }
         & .item::after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 6px;
            left: 0px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--subColor01);
         }
         & .item:not(:last-of-type){
            margin-bottom: 14px;
         }
      }
      & .ceo_img_wrap{
         width: 405px;
         height: 490px;
         background-image: url(${ceoImage});
         background-size: cover;
         background-color: #fff;
      }
   }
   & .history{
      & .tab_btn_wrap{
         width: fit-content;
         margin-left: auto;
         margin-right: auto;
         & .btn{
            width: 280px;
            border-bottom: 2px solid var(--subColor04);
         }
         & .btn.on{
            border-bottom: 2px solid var(--pointColor01);
            color: var(--pointColor01);
         }
      }
      & .year_items{
         & .history_img_wrap{
            width: 100%;
            max-width: 600px;
            & .history_img{
            width: 100%;
            max-width: 385px;
            margin-left: auto;
            }
         }
         & .line{
            position: relative;
            order: 1;
            width: 1px;
            height: auto;
            background-color: var(--subColor03);
         }
         & .line::after{
            content: '';
            display: block;
            clear: both;
            position: absolute;
            top: 0px;
            left: 0px;
            transform: translateX(-50%);
            width: 20px;
            height: 20px;
            background-image: url(${circle01});
            background-size: cover;
         }
         & .text_wrap{
            width: 100%;
            max-width: 600px;
            margin-top: -11px;
            & .desc:not(:last-of-type){
               margin-bottom: 8px;
            }
         }
         &:last-of-type .line{
            background-color: transparent;
         }
         &:last-of-type .history_img_wrap,
         &:last-of-type .text_wrap{
            margin-bottom: 0px;
         }
         &:nth-of-type(2n-1){
            & .history_img_wrap{
               order: 0;
            }
            & .text_wrap{
               order: 2;
            }
         }
         &:nth-of-type(2n){
            & .history_img_wrap{
               order: 2;
               & .history_img{
                  margin-right: auto;
                  margin-left: 0px;
               }
            }
            & .text_wrap{
               order: 0;
               & *{

                  text-align: right;
               }
            }
         }
      }
   }
   @media screen and (max-width:1280px){
      .container {
         flex-direction: column;
      }
      .ceo_text_wrap {
         .ceo_img_wrap{
            width: 100%;
         }
         .ceo_name{
            height: 24px;
         }
      }
      .history{
         .tab_btn_wrap{
            width: 100%;
            .btn{
               width: 33.3%;
            }
         }
         .year_items{
            .item_wrap{
               justify-content: flex-start;
               flex-wrap: wrap;
               border-left: 1px solid var(--subColor03);
               .history_img_wrap{
                  order: 2;
                  width: calc(100% - 1px);
                  padding-left: 24px;
                  .history_img{
                     margin-left: 0px;
                  }
               }
               .line{
                  order: 1;
                  background-color: transparent;
               }
               .line::after{
                  width: 14px;
                  height: 14px;
               }
               .text_wrap{
                  order: 3;
                  width: calc(100% - 1px);
                  min-width: calc(100% - 1px);
                  padding-left: 24px;
                  margin-top: 0px;
                  *{
                     text-align: left;
                  }
               }
            }
         }
      }
   }
   @media screen and (max-width:767px){
      .list{
         width: 33.3%;
      }
   }
`

const About = () => {

   let sct = document.documentElement.scrollTop;
   const [data, setData] = useState<HistoryData>();
   const [decade, setDecade] = useState<number>(2020);


   const decadeHandle = (decade: number) => {
      setDecade(decade)
   }

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/history?decade=${decade}`)
            setData(response.data)
         } catch (error) {
            console.log(error)
         }
      }
      fetchData();
   }, [decade])

   // Tab
   const subPageTab: SubPageTab[] = [
      { name: "인사말" },
      { name: "연혁" },
      { name: "차별성" },
   ]

   useEffect(() => {
      const headerHeight = document.getElementById("header")?.offsetHeight;
      const tabItems = [...document.querySelectorAll(".tab_wrap .item")]
      const targetItems = [
         document.querySelector(".ceo_greeting"),
         document.querySelector(".history"),
         document.querySelector(".distinction"),
      ]

      tabItems.forEach((tabItem, index) => {
         tabItem.addEventListener("click", () => {
            const targetItem = targetItems[index];
            if (targetItem) {
               const targetOffsetTop = Math.ceil(targetItem.getBoundingClientRect().top + sct)
               window.scrollTo({ top: targetOffsetTop - (headerHeight ? headerHeight : 0), behavior: 'smooth' })
            } else {
               console.log("Element not found");
            }
         });
      });

   }, [sct])

   document.addEventListener("scroll", () => {
      sct = document.documentElement.scrollTop;
   })

   // tab 컨트롤
   const location = useLocation();
   const queryString = Number(location.search.substring(1));
   const [currentId, setCurrentId] = useState(queryString ? queryString : 0);

   useEffect(() => {
      setCurrentId(queryString ? queryString : 0)
   }, [queryString])

   return (
      <Container>
         <SubPageTabArea
            data={subPageTab}
            currentId={currentId}
         />
         <section className="ceo_greeting">
            <h3 className="text-center xl:leading-60 leading-32 xl:text-40 text-24 font-bold xl:mb-40 mb-24">
               당신의 성공파트너<br />
               &#40;주&#41;우수조달컨설팅과 함께하세요
            </h3>
            <div className="ceo_text_wrap xl:pt-48 pt-60 xl:pb-80 pb-60">
               <div className="container flex justify-between">
                  <div className="xl:order-1 order-2">
                     <h4 className="xl:leading-44 leading-26 xl:text-28 text-18 font-semibold xl:mb-40 mb-24 xl:mt-0 mt-40">
                        <strong className="point">고객을 최우선</strong>으로 하는 기업 <br />
                        지금까지 어디서도 만나지 못한 <strong className="point">신개념 컨설팅</strong>
                     </h4>
                     <p className="xl:leading-26 leading-24 xl:text-18 text-14">
                        저희 &#40;주&#41;우수조달컨설팅을 찾아주셔서 감사드립니다.<br className="xl:block hidden" />
                        저희 회사는 실무를 겸비한 프로집단으로 고객을 최우선으로 생각하고 있는 기업입니다.
                        <br /><br />
                        2002년도 창업이래 조달우수제품인증과 성능인증을 주력으로 컨설팅한 조달 전문기업입니다.
                        <br className="xl:block hidden" /><br className="xl:block hidden" />
                        요즘, 과거 ISO와 국제인증을 하던 업체에서<br className="xl:block hidden" />
                        우수조달제품인증을 한다고 하고 있으나<br className="xl:block hidden" />
                        실적이 일천하고 노하우도 없어성공확률이 매우 떨어진다고 들었습니다.
                        <br /><br />
                        이러한 문제점을 해결하고자 본사는 특허를 변리사와 본사가 직접 무료심사하여<br className="xl:block hidden" />
                        가능성이 있는 업체만 수임함으로써 성공확률이 국내 최고수준인<br className="xl:block hidden" />
                        95%의 성공률을 보이고 있습니다.
                        <br /><br />
                        앞으로도 &#40;주&#41;우수조달컨설팅은 조달우수제품인증에 주력할것이며,<br className="xl:block hidden" />
                        이를통해 조달컨설팅의 전문기업으로 거듭 날것입니다.
                     </p>
                     <div className="flex items-center xl:gap-16 gap-8 xl:mt-54 mt-24">
                        <p className="xl:text-24 text-14 font-bold">대표</p>
                        <img className="ceo_name" src={ceoName} alt="대표 이름" />
                     </div>
                  </div>
                  <div className="xl:order-2 order-1">
                     <div className="ceo_img_wrap">
                        {/* <img src={ceoImage} alt="" /> */}
                     </div>
                     <div className="xl:pt-0 pt-32">
                        <h5 className="xl:text-20 font-bold xl:mt-32">대표자 약력</h5>
                        <ul className="ceo_profile_wrap xl:mt-20 mt-16">
                           <li className="item leading-1em xl:pl-12 pl-8">1984년&#41; 배재고등학교 99회 졸업</li>
                           <li className="item leading-1em xl:pl-12 pl-8">1992년&#41; 연세대학교 행정학과 졸업</li>
                           <li className="item leading-1em xl:pl-12 pl-8">1992년~2001년&#41; 삼성전자 근무</li>
                           <li className="item leading-1em xl:pl-12 pl-8">2002년~현재&#41; 우수조달컨설팅 대표</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="history container xl:mb-120 xl:pt-0 xl:pb-0 pt-60 pb-60">
            <h3 className="text-center leading-1em xl:text-40 text-24 font-bold xl:mt-80 xl:mb-80 mb-40">연혁</h3>
            <div className="tab_btn_wrap flex">
               <button onClick={() => decadeHandle(2020)} className={`${decade === 2020 ? "on" : ""} btn leading-1em xl:text-16 text-14 font-semibold xl:pt-24 xl:pb-24 pb-16`}>2020~현재</button>
               <button onClick={() => decadeHandle(2010)} className={`${decade === 2010 ? "on" : ""} btn leading-1em xl:text-16 text-14 font-semibold xl:pt-24 xl:pb-24 pb-16`}>2010~2019</button>
               <button onClick={() => decadeHandle(2000)} className={`${decade === 2000 ? "on" : ""} btn leading-1em xl:text-16 text-14 font-semibold xl:pt-24 xl:pb-24 pb-16`}>2000~2009</button>
            </div>
            <div className="xl:mt-80 mt-42">
               {data && data.data.years.length > 0 ? (
                  data.data.years.map((yearsInfo, index) => (
                     <div key={index} className="year_items">
                        <div className="item_wrap flex justify-center">
                           <div className={`history_img_wrap xl:mb-30 xl:pb-0 ${!yearsInfo.image && "xl:block hidden"}`}>
                              <img className="history_img" src={yearsInfo.image} alt="" />
                           </div>
                           <div className="line hide_text xl:ml-40 xl:mr-40">line</div>
                           <div className="text_wrap xl:mb-30 mb-40">
                              <h5 className="leading-1em xl:text-40 text-16 font-bold xl:mb-32 mb-8">{yearsInfo.year}</h5>
                              <ul>
                                 {yearsInfo.histories.map((histories, index) => (
                                    <li className="desc xl:leading-36 leading-24 xl:text-18 text-12 text-subColor03" key={index}>{histories.content}</li>
                                 ))}
                              </ul>
                           </div>
                        </div>
                     </div>
                  ))
               ) : (
                  <p className="text-center">데이터가 없습니다.</p>
               )}
            </div>
         </section >
         <Distinction />
      </Container >
   )
}

export default About;